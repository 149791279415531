import { Divider } from "@mui/material";
import React from "react";
import {
  buildXSoftBlack,
  buildxBlue,
  buildxDarkBlue,
  buildxGreen,
  buildxLightBlue,
  buildxLightStone,
  buildxSand,
  buildxStone,
} from "../data/colors";
import { NormalContainer } from "./NormalContainer";
import { BuildxLogo, Logo } from "./Navbar";
import { Header } from "./Header";
import { VerticalSpace } from "./VerticalSpace";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: buildxSand,
        // boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.8)",
        border: "1px solid" + " rgba(100, 100, 100, 0.03)",
      }}
    >
      <NormalContainer padding="0">
        <div
          style={{
            // height: "200px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* <Logo color={buildxGreen} /> */}
          <div />
          {/* <BuildxLogo color={buildxDarkBlue} /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "30px",
              gap: "40px",
            }}
          >
            <div>
              <VerticalSpace height="20px" />
              <Header
                text="Contact"
                backgroundColor={buildxLightStone}
                color={buildxDarkBlue}
                size="xs"
              />
              <p style={{ fontSize: "14px" }}>info@buildx.se</p>
              <p style={{ fontSize: "14px" }}>+46737129153</p>
              <p style={{ fontSize: "14px" }}>
                {"Klarabergsviadukten 63, " + "111 64 Stockholm"}
              </p>
              <p style={{ fontSize: "14px" }}>
                Koste Glavinića 9, 11040, Belgrade
              </p>
            </div>
            <div>
              <VerticalSpace height="20px" />
              <Header
                text="Services"
                backgroundColor={buildxLightStone}
                color={buildxDarkBlue}
                size="xs"
              />
              <Link style={{ color: "inherit" }} to="/design">
                <p style={{ fontSize: "14px" }}>Projektering</p>
              </Link>
              <Link style={{ color: "inherit" }} to="/turnkey-contract">
                <p style={{ fontSize: "14px" }}>Totalentreprenad</p>
              </Link>
              <Link style={{ color: "inherit" }} to="/digital-solutions">
                <p style={{ fontSize: "14px" }}>Digitala lösningar</p>
              </Link>
              <Link style={{ color: "inherit" }} to="/sustainability">
                <p style={{ fontSize: "14px" }}>Hållbarhet</p>
              </Link>
            </div>
          </div>
        </div>
      </NormalContainer>
    </div>
  );
};
