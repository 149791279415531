export const buildxDarkBlue = "rgb(23,35,45)";
export const buildxBlue = "#005776";
export const buildxLightBlue = "#BBDDE6";
export const buildxRed = "#CC3333";
export const buildxGreen = "#279989";
export const buildxStone = "#E1D3C4";
export const buildxLightStone = "rgb(247,241,237)";
export const buildXSoftBlack = "#171919";
export const buildxSexyBlue = "rgb(132, 167, 180)";
export const buildxSand = "rgb(230, 222, 218)";
