import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { useMediaQuery } from "@mui/material";

export const ParagraphContainer = ({
  children,
}: {
  children: ReactJSXElement | ReactJSXElement[];
}) => {
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  const isBigScreen = useMediaQuery("(min-width:2000px)");

  let styles = {
    paddingLeft: isSmallScreen ? "10px" : !isBigScreen ? "220px" : "0px",
    paddingRight: isSmallScreen ? "10px" : !isBigScreen ? "220px" : "0px",
    // marginTop: "50px",
    marginBottom: "80px",
  };

  return <div style={styles}>{children}</div>;
};
