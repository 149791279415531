import React, { useState, useEffect, useRef } from "react";
import { Header } from "./Header";

interface TypingEffectProps {
  text: string;
  textColor: string;
  backgroundColor?: string;
  setTypingDone: (done: boolean) => void;
}

export const TypingEffect: React.FC<TypingEffectProps> = ({
  text,
  textColor,
  backgroundColor,
  setTypingDone,
}) => {
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);
  const intervalIdRef: any = useRef(null);
  const textLength = text.length;
  console.log("entering TypingEffect");

  useEffect(() => {
    if (index < text.length) {
      intervalIdRef.current = setInterval(() => {
        setDisplayedText(text.substring(0, index + 1));
        setIndex((prevIndex) => prevIndex + 1);
      }, 5);
    } else {
      setTypingDone(true);
      clearInterval(intervalIdRef.current);
    }

    return () => clearInterval(intervalIdRef.current);
  }, [text, index]);

  return (
    <h2
      style={{
        marginTop: "0",
        color: textColor,
        fontFamily: "NeueHaasDisplayXThin",
      }}
      dangerouslySetInnerHTML={{ __html: displayedText }}
    />
  );
};

