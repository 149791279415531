import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Breakpoint, Container, useMediaQuery } from "@mui/material";
import React, { useState } from "react";

export const NormalContainer = ({
  children,
  padding,
  wide,
}: {
  children: ReactJSXElement | ReactJSXElement[];
  padding?: string;
  wide?: boolean;
}) => {
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  const isWideScreen = useMediaQuery("(min-width:1900px)");
  let styles: any = {
    paddingTop: isSmallScreen ? "120px" : "150px",
  };

  if (padding) {
    styles = {
      paddingTop: padding,
    };
  }
  if (isWideScreen)
    return (
      <div style={{ ...styles, paddingLeft: "12%", paddingRight: "12%" }}>
        {children}
      </div>
    );

  return wide || isWideScreen ? (
    <Container maxWidth="xl">
      <div style={styles}>{children}</div>
    </Container>
  ) : (
    <Container maxWidth={"lg"}>
      <div style={styles}>{children}</div>
    </Container>
  );
};
