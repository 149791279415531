import React from "react";
import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <h2>page doesn't exist</h2>
      <Link to="/">Back to Home</Link>
    </div>
  );
};
