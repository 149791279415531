import { Container } from "@mui/system";
import React, { useState } from "react";
import { NormalContainer } from "../../components/NormalContainer";
import { TypingEffect } from "../../components/TypingEffect";
import "./index.css";
import { useTrail, animated, useSpring } from "react-spring";
import {
  useInView,
  IntersectionObserverProps,
} from "react-intersection-observer";
import { Paper, useMediaQuery } from "@mui/material";
import { GifBox } from "@mui/icons-material";
import {
  buildxBlue,
  buildxDarkBlue,
  buildxGreen,
  buildxLightBlue,
  buildxLightStone,
  buildxRed,
  buildxSexyBlue,
  buildxStone,
} from "../../data/colors";
import ScrollDownAnimation from "../../components/ScrollDownAnimation";
import { Link } from "react-router-dom";

// --dark-blue: rgb(3,15,40);
// --blue: #005776;
// --light-blue: #BBDDE6;
// --red: #CC3333;
// --green: #279989;
// --stone: #E1D3C4;
// --light-stone: rgb(247,241,237);
// --soft-black: #171919;

export const GeneralConsultant = () => {
  const spacing: string = "100px";
  const [typingDone, setTypingDone] = useState<boolean>(false);
  const [actionRef, inActionView] = useInView({ threshold: 0.5 });
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  const [ref, inView] = useInView({
    threshold: 0.5, // Trigger when 50% of the list is visible
    // triggerOnce: true, // Only trigger once
  });
  const items = [
    "Rätt information vid rätt tillfälle",
    "Färre byggfel",
    "Informationsdatabas för hela byggnadens livslängd",
  ];
  const trail = useTrail(items.length, {
    from: { opacity: 0, transform: "translate3d(0,-40px,0)" },
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translate3d(0,0px,0)" : "translate3d(0,-40px,0)",
    },
  });
  const [middleHeaderRef, middleHeaderInView] = useInView({ threshold: 0.3 });
  const slideIn = useSpring({
    opacity: middleHeaderInView ? 1 : 0,
    transform: middleHeaderInView ? "translateX(0)" : "translateX(-100%)",
  });
  const animatedStyles = {
    opacity: slideIn.opacity.to((value) => value),
    transform: slideIn.transform.to((value) => value),
  };

  const heroTextAnimation = useSpring({
    // from: { opacity: 0 },
    // to: { opacity: 1 },
    config: { duration: 500 },
    delay: 500,
    opacity: typingDone ? 1 : 0,
  });

  console.log("middleHeaderInView: ", middleHeaderInView);

  return (
    <>
      <ScrollDownAnimation />
      <div style={{ backgroundColor: buildxSexyBlue }}>
        <NormalContainer>
          <div style={{ color: "white" }}>
            {/* <div className="buildx-color-border">
          <h1 style={{ margin: "0" }}>Generalkonsult</h1>
        </div> */}
            {/* <div></div> */}
            {/* <div style={{ height: "100px" }} /> */}
            {typingDone ? (
              <h2 style={{ color: "white" }}>
                BuildX kombinerar{" "}
                <span style={{ color: buildxDarkBlue }}>arkitektur</span> och
                tekniskt kunnande med{" "}
                <span style={{ color: buildxDarkBlue }}>datadrivna</span>{" "}
                processer för att driva tillväxt och besparingar hos byggherrar
                och byggbolag.
              </h2>
            ) : (
              <TypingEffect
                text="BuildX kombinerar arkitektur och tekniskt kunnande med datadrivna processer för att driva tillväxt och besparingar hos byggherrar och byggbolag."
                textColor="white"
                backgroundColor="rgba(10, 20, 30, 1)"
                setTypingDone={setTypingDone}
              />
            )}
          </div>
        </NormalContainer>
        <div style={{ height: "250px" }} />
        <animated.div style={{ ...heroTextAnimation, display: "inline" }}>
          <Container>
            <div className="description-flex">
              {/* <div
              className="subtitle"
              style={{ borderRadius: "14px", backgroundColor: "white" }}
            > */}
              {/* <div className="buildx-color-border"> */}
              <h2 className="subtitle">Samlad trupp</h2>
              {/* </div> */}
              {/* </div> */}
              <div className="description-right-column">
                <p style={{ color: "white" }}>
                  När vi tar uppdrag som generalkonsult har vi integrerade team
                  inomarkitektur, konstruktion, VVS, el och landskap som arbetar
                  i sammakonstellationer i projekt efter projekt
                </p>
                <p style={{ color: "white" }}>
                  Samtliga konsulter arbetar i Autodesk Construction Cloud och
                  Revit. Att vi använder samma BIM-baserade programvara gör att
                  ingen data behöver återskapas. All information kan förädlas
                  från projektering, vidare till byggnation och förvaltning. Vi
                  uppnår därigenom ett{" "}
                  <strong style={{ color: buildxDarkBlue }}>
                    obrutet informationsflöde
                  </strong>
                  . Resultatet blir en snabb och kostnadseffektiv projektering.
                  Vi skapar även informationen som krävs för en byggnation med
                  färre byggfel och den nödvändiga scenarioplaneringen för ökad
                  hållbarhet."
                </p>
              </div>
            </div>
            <div style={{ height: "400px" }} />
            <animated.h2 ref={middleHeaderRef} style={slideIn}>
              <h2 style={{ color: "white" }}>
                Senaste mjukvaran, bästa{" "}
                <span style={{ color: buildxDarkBlue }}>metoderna.</span>
              </h2>
            </animated.h2>
            <div style={{ height: "400px" }} />

            <div className="description-flex">
              <h2 className="subtitle">Varför BuildX?</h2>
              <div
                className="description-right-column"
                style={{ backgroundColor: "rgba(0,0,0,0)", padding: "0" }}
              >
                <ul ref={ref}>
                  {trail.map((style, index) => (
                    <animated.li key={index} style={style} className="li-blobs">
                      {items[index]}
                    </animated.li>
                  ))}
                </ul>
              </div>
            </div>
          </Container>
        </animated.div>
        <div style={{ height: "300px" }} />
      </div>
      <div
        style={{
          border: "1px solid white",
          borderRadius: "15px",
          backgroundColor: buildxLightStone,
        }}
      >
        <NormalContainer padding="0px">
          <div style={{ height: "300px" }} />
          {/* <NormalContainer> */}
          {/* <h2>Resultat</h2> */}
          <div style={{ height: "0px" }}></div>
          <h2>
            - Snabb och{" "}
            <span style={{ backgroundColor: buildxDarkBlue, color: "white" }}>
              kostnadseffektiv
            </span>{" "}
            projektering
          </h2>
          <div></div>
          <h2>- Möjlighet till lägre byggkostnad</h2>
          <div></div>
          <h2>- Ökad hållbarhet</h2>
          <div></div>
          <div style={{ height: "100px" }}></div>
          <div style={{ height: "200px" }}></div>
        </NormalContainer>
      </div>
      <div>
        <NormalContainer>
          <h2>
            <Link to="../contact" style={{ backgroundColor: buildxDarkBlue }}>
              Kontakta oss
            </Link>{" "}
            för ett möte så berättar vi mer.
          </h2>
          <div style={{ height: "200px" }}></div>
        </NormalContainer>
      </div>
      {/* </NormalContainer> */}

      {/* <div className="description-flex">
              <div className="subtitle">
                <h2>Resultat</h2>
              </div>
              {/* <div
                className="description-right-column"
                style={{
                  backgroundColor: "rgb(230,230,230)",
                  boxShadow: "1px 1px 10px 1px grey",
                }}
              >
                <ul ref={ref}>
                  {trail.map((style, index) => (
                    <animated.li key={index} style={style} className="li">
                      {items[index]}
                    </animated.li>
                  ))}
                </ul>
              </div> */}
      {/* </div> */}
      {/* <div style={{ height: "200px" }}></div> */}
      {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <div style={{ backgroundColor: "rgb(230,230,230)" }}>
                <h2>Bemanning</h2>
              </div>
              <div style={{ backgroundColor: "rgb(230,230,230)" }}>
                <h2>Laserskanning</h2>
              </div> */}
      {/* </div> */}

      {/* <Footer /> */}
    </>
  );
};
