import {
  Collapse,
  Dialog,
  Fade,
  IconButton,
  Popover,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import { animated, useSpring } from "@react-spring/web";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { LanguageContext } from "../App";
import {
  buildxBlue,
  buildxDarkBlue,
  buildxGreen,
  buildxLightStone,
  buildxRed,
  buildxSand,
  buildxSexyBlue,
  buildxStone,
} from "../data/colors";
import light_stone_hamburger from "../assets/img/light_stone_hamburger.svg";
import stone_hamburger from "../assets/img/stone_hamburger.svg";
import white_hamburger from "../assets/img/white_hamburger.svg";
import green_hamburger from "../assets/img/green_hamburger.svg";
import dark_blue_hamburger from "../assets/img/dark_blue_hamburger.svg";

const OldServicesDropdown = (props: {
  isServicesDropdownOpen: boolean;
  setServicesDropdownOpen: any;
  color: string;
}) => {
  const { languageFlag } = useContext(LanguageContext);
  const { isServicesDropdownOpen, setServicesDropdownOpen, color } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setServicesDropdownOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setServicesDropdownOpen(false);
  };

  return (
    <div style={{ marginRight: "10px" }}>
      {/* <Link to="#" onClick={handleClick}> */}
      <div
        onClick={(event: any) => handleClick(event)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginRight: "20px",
        }}
      >
        <li
          className="nav-desktop-menu-item"
          style={{
            color: color,
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
          }}
        >
          {!languageFlag ? "Tjänster" : "Services"}
        </li>
        {!isServicesDropdownOpen ? (
          <ExpandMore sx={{ color: color }} />
        ) : (
          <ExpandLess sx={{ color: color }} />
        )}
      </div>
      {/* </Link> */}
      {/* {isServicesDropdownOpen && ( */}
      <Popover
        // id={id}
        open={isServicesDropdownOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ margin: "0" }}
      >
        <div
          style={{
            // position: "absolute",
            marginTop: "0px",
            padding: "10px",
            backgroundColor: buildxSand,
          }}
        >
          <li className="nav-desktop-menu-item">
            <Link
              onClick={() => setServicesDropdownOpen(false)}
              style={{
                display: "block",
                color: buildxDarkBlue,
              }}
              to="./design"
            >
              {languageFlag ? "Design" : "Projektering"}
            </Link>
          </li>
          <li className="nav-desktop-menu-item">
            <Link
              onClick={() => setServicesDropdownOpen(false)}
              style={{
                display: "block",
                color: buildxDarkBlue,
              }}
              to="./turnkey-contract"
            >
              {languageFlag ? "Turnkey contracts" : "Totalentreprenad"}
            </Link>
          </li>
          <li className="nav-desktop-menu-item">
            <Link
              onClick={() => setServicesDropdownOpen(false)}
              style={{
                display: "block",
                color: buildxDarkBlue,
              }}
              to="./carbon-cost-analysis"
            >
              {languageFlag ? "Carbon/Cost Analysis" : "Carbon/Cost Analys"}
            </Link>
          </li>
          {/* <li className="nav-desktop-menu-item">
            <Link
              onClick={() => setServicesDropdownOpen(false)}
              to="./staffing"
              style={{
                display: "block",
                color: color,
              }}
            >
              {languageFlag ? "Staffing" : "Bemanning"}
            </Link>
          </li> */}
        </div>
      </Popover>
      {/* )} */}
    </div>
  );
};

export const BuildxLogo = ({
  color,
  size,
}: {
  color: string;
  size?: string;
}) => {
  const isBigScreen = useMediaQuery("(min-width: 1900px)");
  const location = useLocation();

  if (size && isBigScreen)
    size = `${Math.max(0, parseInt(size, 10) + parseInt(size, 10) * 0.6)}px`;

  if (color === buildxGreen) color = buildxDarkBlue;

  if (location.pathname === "/carbon-cost-analysis") {
    return null;
  }

  return (
    <svg
      width={size ? size : isBigScreen ? "250px" : "170px"}
      // height="20px"
      viewBox="0 0 65 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>buildx_logo</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="buildx_logo" transform="translate(-1.000000, -2.000000)">
          <g
            id="cut-it-copy"
            transform="translate(50.000000, 2.000000)"
            stroke="#B2B2B2"
            stroke-width="0.3"
          >
            <path
              d="M3.91917633,10 C3.91917633,13.4109286 7.47184031,16.3966053 11.8260838,16.3966066 L11.8260838,19.0369124 C11.6807655,19.0412765 11.5347469,19.0434783 11.3880808,19.0434783 C5.0986174,19.0434783 0,14.9945751 0,10"
              id="Path-Copy"
              fill="#CC3333"
              transform="translate(5.913042, 14.521739) scale(-1, 1) translate(-5.913042, -14.521739) "
            ></path>
            <path
              d="M9.60759401,10 C9.60759401,13.4109286 7.47184031,14.5217378 11.8260838,14.5217391 L10.9472162,14.9747417 C10.8018978,14.9791057 10.3514947,15.6238151 10.2048285,15.6238151 C9.21889577,15.6238151 11.8595691,14.7087767 10.9472162,14.5217391 C9.82296323,14.2912609 11.9528434,15.5426761 10.9472162,15.6238151 C10.6107022,15.6509667 9.94530644,15.7256955 9.68988865,16.0929254 C9.55720021,16.2836998 9.8388937,16.9842186 9.68988865,16.8832843 C7.24115716,15.2245423 5.68841768,12.7562692 5.68841768,10"
              id="Path-Copy"
              fill="#279989"
              transform="translate(8.757251, 13.446587) scale(-1, 1) translate(-8.757251, -13.446587) "
            ></path>
            <path
              d="M7.95164495,10.0434783 C7.95164495,13.4544068 11.4718403,16.4400835 15.8260838,16.4400848 L15.8260838,19.0803907 C15.6807655,19.0847548 15.5347469,19.0869565 15.3880808,19.0869565 C9.0986174,19.0869565 4,15.0380534 4,10.0434783"
              id="Path"
              fill="#005776"
            ></path>
            <path
              d="M7.95164495,1 C7.95164495,4.41092856 11.4718403,7.39660525 15.8260838,7.39660657 L15.8260838,10.0369124 C15.6807655,10.0412765 15.5347469,10.0434783 15.3880808,10.0434783 C9.0986174,10.0434783 4,5.99457514 4,1"
              id="Path"
              fill="#BBDDE6"
              transform="translate(9.913042, 5.521739) scale(1, -1) translate(-9.913042, -5.521739) "
            ></path>
            <path
              d="M7.95164495,0.998060412 C7.95164495,4.40898897 4.98415813,5.52076801 9.33840167,5.52076934 C8.60994625,6.66321016 8.14769401,7.39902612 7.95164495,7.72821721 C7.8386929,7.91787794 9.27382927,8.88535517 11.8260838,9.62432003 C12.4481707,9.80443541 13.552709,9.94350829 15.1396987,10.0415387 C14.9943804,10.0459027 15.5347469,10.0415387 15.3880808,10.0415387 C9.0986174,10.0415387 4,5.99263555 4,0.998060412"
              id="Path"
              fill="#005776"
              transform="translate(9.706788, 5.520769) scale(1, -1) translate(-9.706788, -5.520769) "
            ></path>
            <path
              d="M3.91917633,1 C3.91917633,4.41092856 7.47184031,7.39660525 11.8260838,7.39660657 L11.8260838,10.0369124 C11.6807655,10.0412765 11.5347469,10.0434783 11.3880808,10.0434783 C5.0986174,10.0434783 0,5.99457514 0,1"
              id="Path-Copy"
              fill="#279989"
              transform="translate(5.913042, 5.521739) scale(-1, -1) translate(-5.913042, -5.521739) "
            ></path>
          </g>
          <text
            id="Build"
            // font-family="BrownProTT-Bold, BrownProTT"
            font-family="arial"
            font-size="19"
            font-weight="bold"
            fill={color}
          >
            <tspan x="0" y="19">
              Build
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};

export const Logo = ({ color }: { color: string }) => {
  // if (color === buildxSexyBlue) {
  //   return (
  //     <svg
  //       width="50px"
  //       // height="200px"
  //       viewBox="0 0 18 20"
  //       version="1.1"
  //       xmlns="http://www.w3.org/2000/svg"
  //     ></svg>
  //   );
  // } else

  const isBigScreen = useMediaQuery("(min-width: 1900px)");

  return (
    <svg
      width={isBigScreen ? "70px" : "50px"}
      // height="200px"
      viewBox="0 0 18 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>light stone symbol</title>

      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        style={{ marginLeft: "60px" }}
      >
        <g
          id="light-stone-symbol"
          transform="translate(1.000000, 0.000000)"
          fill={color !== buildxSexyBlue ? color : "none"}
          stroke={color !== buildxSexyBlue ? "#A6A6A6" : "none"}
          stroke-width="0.4"
          style={{
            transition: "fill 0.2s ease-in-out",
          }}
        >
          <path
            d="M3.91917633,10 C3.91917633,13.4109286 7.47184031,16.3966053 11.8260838,16.3966066 L11.8260838,19.0369124 C11.6807655,19.0412765 11.5347469,19.0434783 11.3880808,19.0434783 C5.0986174,19.0434783 0,14.9945751 0,10"
            id="Path-Copy"
            transform="translate(5.913042, 14.521739) scale(-1, 1) translate(-5.913042, -14.521739) "
            fill={color === buildxGreen ? buildxRed : "inherit"}
            style={{
              transition: "fill 0.2s ease-in-out",
            }}
          ></path>
          <path
            d="M9.60759401,10 C9.60759401,13.4109286 7.47184031,14.5217378 11.8260838,14.5217391 L10.9472162,14.9747417 C10.8018978,14.9791057 10.3514947,15.6238151 10.2048285,15.6238151 C9.21889577,15.6238151 11.8595691,14.7087767 10.9472162,14.5217391 C9.82296323,14.2912609 11.9528434,15.5426761 10.9472162,15.6238151 C10.6107022,15.6509667 9.94530644,15.7256955 9.68988865,16.0929254 C9.55720021,16.2836998 9.8388937,16.9842186 9.68988865,16.8832843 C7.24115716,15.2245423 5.68841768,12.7562692 5.68841768,10"
            id="Path-Copy"
            transform="translate(8.757251, 13.446587) scale(-1, 1) translate(-8.757251, -13.446587) "
            fill={color === buildxGreen ? buildxGreen : "inherit"}
            style={{
              transition: "fill 0.2s ease-in-out",
            }}
          ></path>
          <path
            d="M7.95164495,10.0434783 C7.95164495,13.4544068 11.4718403,16.4400835 15.8260838,16.4400848 L15.8260838,19.0803907 C15.6807655,19.0847548 15.5347469,19.0869565 15.3880808,19.0869565 C9.0986174,19.0869565 4,15.0380534 4,10.0434783"
            id="Path"
            fill={color === buildxGreen ? buildxBlue : "inherit"}
            style={{
              transition: "fill 0.2s ease-in-out",
            }}
          ></path>
          <path
            d="M7.95164495,1 C7.95164495,4.41092856 11.4718403,7.39660525 15.8260838,7.39660657 L15.8260838,10.0369124 C15.6807655,10.0412765 15.5347469,10.0434783 15.3880808,10.0434783 C9.0986174,10.0434783 4,5.99457514 4,1"
            id="Path"
            transform="translate(9.913042, 5.521739) scale(1, -1) translate(-9.913042, -5.521739) "
            fill={color === buildxGreen ? buildxSexyBlue : "inherit"}
            style={{
              transition: "fill 0.2s ease-in-out",
            }}
          ></path>
          <path
            d="M7.95164495,0.998060412 C7.95164495,4.40898897 4.98415813,5.52076801 9.33840167,5.52076934 C8.60994625,6.66321016 8.14769401,7.39902612 7.95164495,7.72821721 C7.8386929,7.91787794 9.27382927,8.88535517 11.8260838,9.62432003 C12.4481707,9.80443541 13.552709,9.94350829 15.1396987,10.0415387 C14.9943804,10.0459027 15.5347469,10.0415387 15.3880808,10.0415387 C9.0986174,10.0415387 4,5.99263555 4,0.998060412"
            id="Path"
            transform="translate(9.706788, 5.520769) scale(1, -1) translate(-9.706788, -5.520769) "
            fill={color === buildxGreen ? buildxBlue : "inherit"}
            style={{
              transition: "fill 0.2s ease-in-out",
            }}
          ></path>
          <path
            d="M3.91917633,1 C3.91917633,4.41092856 7.47184031,7.39660525 11.8260838,7.39660657 L11.8260838,10.0369124 C11.6807655,10.0412765 11.5347469,10.0434783 11.3880808,10.0434783 C5.0986174,10.0434783 0,5.99457514 0,1"
            id="Path-Copy"
            transform="translate(5.913042, 5.521739) scale(-1, -1) translate(-5.913042, -5.521739) "
            fill={color === buildxGreen ? buildxGreen : "inherit"}
            style={{
              transition: "fill 0.2s ease-in-out",
            }}
          ></path>
        </g>
      </g>
    </svg>
  );
};

const Hamburger = ({
  color,
  setOpenMobileMenu,
}: {
  color: string;
  setOpenMobileMenu: any;
}) => {
  return (
    <IconButton
      sx={{ padding: "0", margin: "0" }}
      disableRipple
      onClick={() => setOpenMobileMenu(true)}
    >
      {/* <div style={{ display: "flex", justifyContent: "flex-end" }}> */}
      <svg
        width="45px"
        // height="153px"
        viewBox="0 0 173 153"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>dark_blue_hamburger</title>
        <desc>Created with Sketch.</desc>
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="dark_blue_hamburger"
            fill={color}
            style={{
              transition: "fill 0.2s ease-in-out",
            }}
          >
            <rect id="Rectangle" x="0" y="0" width="173" height="31"></rect>
            <rect
              id="Rectangle-Copy"
              x="0"
              y="61"
              width="173"
              height="31"
            ></rect>
            <rect
              id="Rectangle-Copy-2"
              x="0"
              y="122"
              width="173"
              height="31"
            ></rect>
          </g>
        </g>
      </svg>
      {/* </div> */}
    </IconButton>
  );
};

const MobileNavDialog = ({
  setOpenMobileMenu,
  setNavColorFlag,
}: {
  setOpenMobileMenu(arg: boolean): void;
  setNavColorFlag(arg: number): void;
}) => {
  let { languageFlag, setLanguage } = useContext(LanguageContext);
  const [servicesOpen, setServicesOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  const location = useLocation();

  useEffect(() => {
    setNavColorFlag(10);
  }, []);

  return (
    <div style={{ backgroundColor: buildxLightStone, padding: "15px" }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton
          onClick={() => setOpenMobileMenu(false)}
          size="large"
          style={{ color: buildxDarkBlue, padding: "0px", margin: "0px" }}
        >
          <ClearIcon fontSize="large" style={{ fontSize: "50px" }} />
        </IconButton>
      </div>

      <div
        style={{
          color: "white",
          height: "90vh",
          display: "flex",
          // alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "column",
          textAlign: isSmallScreen ? "left" : "left",
          fontSize: !isSmallScreen ? "45px" : "0px",
        }}
      >
        <Link
          to="#"
          onClick={(event) => {
            event.preventDefault();
            setServicesOpen(!servicesOpen);
          }}
          className={highlightMenuItem(location.pathname, "/", 0)}
        >
          <li className="mobile-li">
            {languageFlag ? "Services" : "Tjänster"}
          </li>
          {!servicesOpen ? (
            <ExpandMore fontSize="large" />
          ) : (
            <ExpandLess fontSize="large" />
          )}
        </Link>
        <Collapse in={servicesOpen}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: isSmallScreen
                ? "10px 0px 10px 40px"
                : "10px 0px 10px 0px",
            }}
          >
            <Link
              to="/design"
              onClick={() => setOpenMobileMenu(false)}
              style={{ margin: "5px 0px 5px 0px" }}
            >
              <li
                className="mobile-li"
                style={{ fontSize: isSmallScreen ? "32px" : "50px" }}
              >
                {languageFlag ? "Design" : "Projektering"}
              </li>
            </Link>
            <Link
              to="/turnkey-contract"
              onClick={() => setOpenMobileMenu(false)}
              style={{ margin: "5px 0px 5px 0px" }}
            >
              <li
                className="mobile-li"
                style={{ fontSize: isSmallScreen ? "32px" : "50px" }}
              >
                {!languageFlag ? "Totalentreprenad" : "Turnkey Contract"}
              </li>
            </Link>
            {/* <Link
              to="/staffing"
              onClick={() => setOpenMobileMenu(false)}
              style={{ margin: "5px 0px 5px 0px" }}
            >
              <li
                className="mobile-li"
                style={{ fontSize: isSmallScreen ? "32px" : "50px" }}
              >
                {languageFlag ? "Staffing" : "Bemanning"}
              </li>
            </Link> */}
          </div>
        </Collapse>
        <Link to="/projects" onClick={() => setOpenMobileMenu(false)}>
          <li className="mobile-li">{languageFlag ? "Projects" : "Projekt"}</li>
        </Link>
        <Link to="/sustainability" onClick={() => setOpenMobileMenu(false)}>
          <li className="mobile-li">
            {languageFlag ? "Sustainability" : "Hållbarhet"}
          </li>
        </Link>
        <Link to="/digital-solutions" onClick={() => setOpenMobileMenu(false)}>
          <li className="mobile-li">
            {languageFlag ? "Digital Solutions" : "Digitala lösningar"}
          </li>
        </Link>
        <Link to="/about" onClick={() => setOpenMobileMenu(false)}>
          <li className="mobile-li">{languageFlag ? "About" : "Om"}</li>
        </Link>
        <Link to="/contact" onClick={() => setOpenMobileMenu(false)}>
          <li className="mobile-li">{languageFlag ? "Contact" : "Kontakt"}</li>
        </Link>
        <Link to="#" onClick={(event) => event.preventDefault()}>
          <li
            className="mobile-li"
            onClick={() => {
              setLanguage((prev: number) => !prev);
              setOpenMobileMenu(false);
            }}
            style={{ display: "inline" }}
          >
            {languageFlag ? "sv" : "en"}
          </li>
        </Link>
      </div>
    </div>
  );
};

const highlightMenuItem = (
  path: string,
  pageName: string,
  navColorFlag: number
): string => {
  if (pageName === path) {
    if (navColorFlag === 1) {
      return "nav-item-night nav-item-highlight";
    } else {
      return "nav-item-basic nav-item-highlight";
    }
  } else {
    if (navColorFlag === 1) {
      return "nav-item-basic nav-item-night";
    } else {
      return "nav-item-basic";
    }
  }
};

const NavDesktopLinks = (props: any) => {
  const {
    language,
    setLanguage,
    color,
    isServicesDropdownOpen,
    setServicesDropdownOpen,
  } = props;
  const location = useLocation();

  const menuItemMarginRight = "30px";

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {location.pathname === "/carbon-cost-analysis" && (
        <Link
          style={{
            marginRight: menuItemMarginRight,
            color: color,
          }}
          to="./"
        >
          <li className="nav-desktop-menu-item">{language ? "Home" : "Hem"}</li>
        </Link>
      )}
      <OldServicesDropdown
        isServicesDropdownOpen={isServicesDropdownOpen}
        setServicesDropdownOpen={setServicesDropdownOpen}
        color={color}
      />
      <Link
        style={{
          marginRight: menuItemMarginRight,
          color: color,

          textDecoration:
            location.pathname === "/digital-solutions"
              ? "underline solid " + color
              : "",
        }}
        to="./digital-solutions"
      >
        <li className="nav-desktop-menu-item">
          {language ? "Digital Solutions" : "Digitala lösningar"}
        </li>
      </Link>
      <Link
        style={{
          marginRight: menuItemMarginRight,
          color: color,

          textDecoration:
            location.pathname === "/sustainability"
              ? "underline solid " + color
              : "",
        }}
        to="./sustainability"
      >
        <li className="nav-desktop-menu-item">
          {language ? "Sustainability" : "Hållbarhet"}
        </li>
      </Link>

      {/* <Link
        style={{
          marginRight: menuItemMarginRight,
          color: color,

          textDecoration:
            location.pathname === "/carbon-cost-analysis" ? "underline solid " + color : "",
        }}
        to="./carbon-cost-analysis"
      >
        <li className="nav-desktop-menu-item">
          {language ? "Carbon/Cost Analysis" : "Analys"}
        </li>
      </Link> */}

      <Link
        style={{
          marginRight: menuItemMarginRight,
          color: color,

          textDecoration:
            location.pathname === "/projects" ? "underline solid " + color : "",
        }}
        to="./projects"
      >
        <li className="nav-desktop-menu-item">
          {language ? "Projects" : "Projekt"}
        </li>
      </Link>
      <Link
        style={{
          marginRight: menuItemMarginRight,
          color: color,
          textDecoration:
            location.pathname === "/about" ? "underline solid " + color : "",
        }}
        to="./about"
      >
        <li className="nav-desktop-menu-item">{language ? "About" : "Om"}</li>
      </Link>
      <Link
        style={{
          marginRight: menuItemMarginRight,
          color: color,
          textDecoration:
            location.pathname === "/contact" ? "underline solid " + color : "",
        }}
        to="./contact"
      >
        <li className="nav-desktop-menu-item">
          {language ? "Contact" : "Kontakt"}
        </li>
      </Link>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Link
          onClick={(event) => {
            event.preventDefault();
            setLanguage(false);
          }}
          className="nav-desktop-menu-item"
          style={{
            color: color,
            textDecoration: !language ? "underline solid " + color : "",
          }}
          to="#"
        >
          sv
        </Link>
        <p
          style={{
            margin: "0",
            padding: "0px 10px 0px 10px",
            color: color,
          }}
        >
          |
        </p>
        <Link
          onClick={(event) => {
            event.preventDefault();
            setLanguage(true);
          }}
          className="nav-desktop-menu-item"
          style={{
            color: color,
            textDecoration: language && "underline solid " + color,
          }}
          to="#"
        >
          en
        </Link>
      </div>
    </div>
  );
};

const decidenotchBackgroundColor = (
  pathname: string,
  setNotchBackgroundColor: any,
  navColorFlag: number,
  setNavColorFlag: any
) => {
  if (navColorFlag === 10) {
    setNotchBackgroundColor(buildxLightStone);
  } else if (pathname === "/general-consult") {
    setNotchBackgroundColor(buildxSexyBlue);
  } else if (pathname === "/carbon-cost-analysis") {
    if (navColorFlag === 6) {
      setNotchBackgroundColor(buildxDarkBlue);
    } else {
      setNotchBackgroundColor(buildxLightStone);
    }
  } else if (pathname === "/design-collaboration") {
    if (navColorFlag === 3) {
      setNotchBackgroundColor(buildxDarkBlue);
    } else {
      setNotchBackgroundColor(buildxLightStone);
    }
  } else if (pathname === "/contact") {
    setNotchBackgroundColor(buildxBlue);
  } else if (pathname === "/laser-scanning") {
    if (navColorFlag === 2) {
      setNotchBackgroundColor(buildxGreen);
    } else {
      setNotchBackgroundColor(buildxSand);
    }
  } else if (pathname === "/about") {
    if (navColorFlag === 3) {
      setNotchBackgroundColor(buildxDarkBlue);
    } else {
      setNotchBackgroundColor(buildxLightStone);
    }
  } else if (pathname === "/projects") {
    setNotchBackgroundColor(buildxLightStone);
  } else {
    setNotchBackgroundColor(buildxLightStone);
  }
};

const styleNavBar = (
  navColorFlag: number,
  setTextColor: any,
  setLogoColor: any,
  setHamburgerColor: any,
  setNavbarBackgroundColor: any
): void => {
  console.log("style navbar: ", navColorFlag);

  if (navColorFlag === 1) {
    setTextColor("white");
    setLogoColor("white");
    setHamburgerColor("white");
    setNavbarBackgroundColor(buildxLightStone);
  } else if (navColorFlag === 2) {
    setTextColor(buildxSand);
    setLogoColor(buildxStone);
    setHamburgerColor(buildxStone);
    setNavbarBackgroundColor("rgba(0,0,0,0)");
  } else if (navColorFlag === 3) {
    setTextColor(buildxLightStone);
    setLogoColor(buildxLightStone);
    setHamburgerColor(buildxLightStone);
    setNavbarBackgroundColor("rgba(0,0,0,0)");
  } else if (navColorFlag === 4) {
    setHamburgerColor(green_hamburger);
    setNavbarBackgroundColor(buildxLightStone);
    setLogoColor(buildxGreen);
  } else if (navColorFlag === 5) {
    setLogoColor(buildxGreen);
    setHamburgerColor(buildxDarkBlue);
    setNavbarBackgroundColor(buildxLightStone);
    setTextColor(buildxDarkBlue);
  } else if (navColorFlag === 6) {
    setLogoColor(buildxSexyBlue);
    setHamburgerColor(buildxSand);
    setTextColor(buildxStone);

    setNavbarBackgroundColor("rgba(0,0,0,0)");
  } else if (navColorFlag === 7) {
    setHamburgerColor(buildxDarkBlue);
    setNavbarBackgroundColor("rgba(0,0,0,0)");
  } else if (navColorFlag === 8) {
    setLogoColor(buildxGreen);
    setTextColor(buildxSand);
    setHamburgerColor(buildxGreen);
    setNavbarBackgroundColor("rgba(0,0,0,0)");
  } else {
    setTextColor(buildxDarkBlue);
    setLogoColor(buildxDarkBlue);
    setHamburgerColor(buildxDarkBlue);
    setNavbarBackgroundColor(buildxLightStone);
  }
};

const decideNavFlag = (pathname: string): number => {
  if (pathname === "/general-consult") {
    return 1;
  } else if (pathname === "/design-collaboration") {
    return 3;
  } else if (pathname === "/contact") {
    return 2;
  } else if (pathname === "/laser-scanning") {
    return 2;
  } else if (pathname === "/sustainability") {
    return 5;
  } else if (pathname === "/about") {
    return 2;
  } else if (
    pathname === "/digital-solutions" ||
    pathname === "/design" ||
    pathname === "/turnkey-contract" ||
    pathname === "/staffing"
  ) {
    return 5;
  } else if (pathname === "/projects" || pathname.includes("/projects/")) {
    return 5;
  } else if (pathname === "/carbon-cost-analysis") {
    return 6;
  } else if (pathname === "/") {
    return 5;
  } else if (pathname == "/solstudie-novi-dorcol") {
    return 8;
  } else {
    return 0;
  }
};

const useNavbar = (
  isScrollTop: boolean,
  navColorFlag: number,
  setNavColorFlag: any
) => {
  const location = useLocation();
  const [notchBackgroundColor, setNotchBackgroundColor] = useState("");
  const [textColor, setTextColor] = useState<string>("");
  const [logoColor, setLogoColor] = useState<string>(buildxDarkBlue);
  const [navbarBackgroundColor, setNavbarBackgroundColor] =
    useState<string>(buildxLightStone);
  const [hamburgerColor, setHamburgerColor] =
    useState<string>(dark_blue_hamburger);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  // const [navLogo, setNavLogo] = useState(color_logo);

  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    metaThemeColor?.setAttribute("content", notchBackgroundColor);
  }, [notchBackgroundColor]);

  useEffect(() => {
    let localFlag = 0;
    localFlag = decideNavFlag(location.pathname);
    setNavColorFlag(localFlag);
  }, [location.pathname, openMobileMenu]);

  useEffect(() => {
    console.log("pathname: ", location.pathname);
    styleNavBar(
      navColorFlag,
      setTextColor,
      setLogoColor,
      setHamburgerColor,
      setNavbarBackgroundColor
    );
    decidenotchBackgroundColor(
      location.pathname,
      setNotchBackgroundColor,
      navColorFlag,
      setNavColorFlag
    );
    console.log("navBackgroundCOlor: ", navbarBackgroundColor);
  }, [navColorFlag, isScrollTop, location.pathname]);

  return {
    notchBackgroundColor,
    logoColor,
    textColor,
    hamburgerColor,
    openMobileMenu,
    setOpenMobileMenu,
    navbarBackgroundColor,
  };
};

export const NavBar = ({
  navColorFlag,
  setNavColorFlag,
  isScrollTop,
}: {
  navColorFlag: number;
  setNavColorFlag: any;
  isScrollTop: boolean;
}) => {
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  const [logoSize, setLogoSize] = useState("192px");
  // const [logoPadding, setLogoPadding] = useState("0px");
  const [imgTransform, setImgTransform] = useState("scaleX(1)");
  const location = useLocation();
  const [servicesOpen, setServicesOpen] = useState(false);
  const [isServicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  let { languageFlag, setLanguage } = useContext(LanguageContext);
  const {
    notchBackgroundColor,
    navbarBackgroundColor,
    logoColor,
    textColor,
    hamburgerColor,
    openMobileMenu,
    setOpenMobileMenu,
  } = useNavbar(isScrollTop, navColorFlag, setNavColorFlag);

  const menuItemMarginRight = "30px";

  const toggleServicesDropdown = () => {
    setServicesDropdownOpen(!isServicesDropdownOpen);
  };

  const menuAnimation = useSpring({
    opacity: openMobileMenu ? 1 : 0,
    // transform: openMobileMenu ? "translateY(0%)" : "translateY(-40%)",
    config: { duration: 300, delay: 0 },
  });

  const props = useSpring({
    opacity: isScrollTop ? 1 : 0, // Animate opacity to 1 when isScrollTop is true
    transform: isScrollTop ? "translateX(0)" : "translateX(10%)", // Animate transform to 0 when isVisible is true
    from: { opacity: 0, transform: "translateX(100%)" },
  });

  const secondProps = useSpring(
    !isSmallScreen
      ? {
          opacity: !isScrollTop ? 1 : 0, // Animate opacity to 1 when isScrollTop is true
          from: { opacity: 0 },
        }
      : {
          opacity: !isScrollTop ? 1 : 1, // Animate opacity to 1 when isScrollTop is true
          from: { opacity: 0 },
        }
  );

  return (
    <>
      <div
        className="navbar"
        style={{
          // backgroundColor: isScrollTop ? buildxLightStone : "rgba(0,0,0,0)",
          zIndex: "2",
          backgroundColor: navbarBackgroundColor,
          // transition: "background-color .1s ease-in-out",
          // backdropFilter: isScrollTop ? "blur(0px)" : "blur(5px)",
          // borderBottom: isScrollTop ? "0" : "1px solid grey",
          //   !isSmallScreen && !isScrollTop ? "0.1px solid " + color : "",
        }}
      >
        <div style={{ transform: imgTransform }}>
          <Link to="./">
            {location.pathname === "/" ? (
              isScrollTop ? (
                <BuildxLogo color={logoColor} />
              ) : (
                <Logo color={logoColor} />
              )
            ) : (
              <Logo color={logoColor} />
            )}
            {/* <img style={{ width: logoSize }} src={navLogo}></img> */}
          </Link>
        </div>

        {!isSmallScreen && isScrollTop ? (
          <animated.div style={props}>
            <NavDesktopLinks
              language={languageFlag}
              setLanguage={setLanguage}
              color={textColor}
              isServicesDropdownOpen={isServicesDropdownOpen}
              setServicesDropdownOpen={setServicesDropdownOpen}
            />
          </animated.div>
        ) : (
          <animated.div style={secondProps}>
            {/* <Fade
              in={!isScrollTop || location.pathname === "/carbon-cost-analysis"}
              timeout={100}
            > */}
            <Hamburger
              color={hamburgerColor}
              setOpenMobileMenu={setOpenMobileMenu}
            />
            {/* </Fade> */}
          </animated.div>
        )}
      </div>
      <Dialog
        transitionDuration={0}
        onClose={() => setOpenMobileMenu(false)}
        fullScreen
        open={openMobileMenu}
      >
        <animated.div style={menuAnimation}>
          <MobileNavDialog
            setOpenMobileMenu={setOpenMobileMenu}
            setNavColorFlag={setNavColorFlag}
          />
        </animated.div>
      </Dialog>
    </>
  );
};
