import React from "react";

export const PdfViewer = () => {
  return (
    <div>
      <embed
        src="/path-to-your-pdf.pdf"
        type="application/pdf"
        width="100%"
        height="600px"
      />
    </div>
  );
};
