import { useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { LoadedContext } from "../App";
import { buildxLightStone } from "../data/colors";

interface HeroBoxProps {
  backgroundColor: string;
  header: string;
  text: string;
  headerColor?: string;
  direction?: string;
}

const HeroBox = (props: HeroBoxProps) => {
  const { backgroundColor, header, text, direction, headerColor } = props;
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  const isBigScreen = useMediaQuery("(min-width:1900px)");

  return (
    // <animated.div style={{ ...heroTextAnimation }}>
    <div
      style={{
        display: "flex",
        justifyContent: direction,
      }}
    >
      <div
        style={{
          backgroundColor: backgroundColor,
          display: "inline",
          textAlign: "center",
          // width: "40%",
          padding: isSmallScreen
            ? "10px 26px 10px 26px"
            : "20px 40px 20px 40px",
        }}
      >
        <h2
          style={{
            fontSize: isBigScreen ? "85px" : isSmallScreen ? "40px" : "60px",
            paddingLeft: "0",
            backgroundColor: headerColor ?? "",
            color: buildxLightStone,
          }}
          className="header"
        >
          {header}
        </h2>
        {/* <p>{text}</p> */}
      </div>
    </div>
    // </animated.div>
  );
};

export const HeroSection = (props: any) => {
  const { backgroundColor, header, headerColor, text, direction, image } =
    props;
  const location = useLocation();
  const imageURL: string = "/assets/img/VR.jpeg";

  // if (location.pathname === "/sustainability") {
  //   imagePath = `url(${parkModern})`;
  // } else if (location.pathname === "/digital-solutions") {
  //   imagePath = `url(${vrImage})`;
  // } else if (location.pathname === "/projects") {
  //   imagePath = `url(${cobeImage})`;
  // }

  // useEffect(() => {
  //   setIsLoaded(false);
  // }, []);

  const containerStyle: React.CSSProperties = {
    backgroundImage: `url(${image})`,
    filter: `brightness(1)`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    height: "70vh", // Set the desired height of your component
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      {/* <div style={{ padding: "40px" }}></div> */}
      <div style={containerStyle}>
        {/* <NormalContainer> */}
        {/* <div style={{ padding: "500px" }} /> */}
        {/* <h1 style={{ color: "white" }}>Hållbarhet</h1> */}
        {/* <div style={{ paddingTop: "350px" }}></div> */}
        <HeroBox
          backgroundColor={backgroundColor}
          header={header}
          headerColor={headerColor}
          text={text}
          direction={direction}
        />
        {/* </NormalContainer> */}
      </div>
    </div>
  );
};
