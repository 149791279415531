import { Header } from "../components/Header";
import { HeroSection } from "../components/HeroSection";
import { NormalContainer } from "../components/NormalContainer";
import { PageDiv } from "../components/PageDiv";
import { ParagraphContainer } from "../components/ParagraphContainer";
import {
  buildxBlue,
  buildxGreen,
  buildxLightStone,
  buildxSand,
  buildxStone,
} from "../data/colors";
import vrImage from "../assets/img/VR.png";
import noviDorcol from "../assets/img/novi-dorcol.jpeg";
import { useState } from "react";
import { Slider, useMediaQuery } from "@mui/material";
import five_am from "../assets/img/0500.png";
import six_am from "../assets/img/0600.png";
import seven_am from "../assets/img/0700.png";
import eight_am from "../assets/img/0800.png";
import nine_am from "../assets/img/0900.png";
import ten_am from "../assets/img/1000.png";
import eleven_am from "../assets/img/1100.png";
import twelve_pm from "../assets/img/1200.png";
import one_pm from "../assets/img/1300.png";
import two_pm from "../assets/img/1400.png";
import three_pm from "../assets/img/1500.png";
import four_pm from "../assets/img/1600.png";
import five_pm from "../assets/img/1700.png";
import six_pm from "../assets/img/1800.png";
import seven_pm from "../assets/img/1900.png";
import { Lien } from "../components/Lien";

interface TimelineItem {
  id: number;
  hour: number;
  imageUrl: string;
  label?: string;
}

const marks: { value: number; label: string }[] = [
  {
    value: 6,
    label: "06:00",
  },
  {
    value: 7,
    label: "",
  },
  {
    value: 8,
    label: "08:00",
  },
  {
    value: 9,
    label: "",
  },
  {
    value: 10,
    label: "10:00",
  },
  {
    value: 11,
    label: "",
  },
  {
    value: 12,
    label: "12:00",
  },
  {
    value: 13,
    label: "",
  },
  {
    value: 14,
    label: "14:00",
  },
  {
    value: 15,
    label: "",
  },
  {
    value: 16,
    label: "16:00",
  },
  {
    value: 17,
    label: "",
  },
  {
    value: 18,
    label: "18:00",
  },
  {
    value: 19,
    label: "",
  },
  {
    value: 20,
    label: "20:00",
  },
];

const timelineData: TimelineItem[] = [
  {
    id: 1,
    hour: 6,
    label: "lol",
    imageUrl: five_am,
  },
  { id: 2, hour: 7, imageUrl: six_am },
  { id: 3, hour: 8, imageUrl: seven_am },
  { id: 4, hour: 9, imageUrl: eight_am },
  { id: 5, hour: 10, imageUrl: nine_am },
  { id: 6, hour: 11, imageUrl: ten_am },
  { id: 7, hour: 12, imageUrl: eleven_am },
  { id: 8, hour: 13, imageUrl: twelve_pm },
  { id: 9, hour: 14, imageUrl: one_pm },
  { id: 10, hour: 15, imageUrl: two_pm },
  { id: 11, hour: 16, imageUrl: three_pm },
  { id: 12, hour: 17, imageUrl: four_pm },
  { id: 13, hour: 18, imageUrl: five_pm },
  { id: 14, hour: 19, imageUrl: six_pm },
  { id: 15, hour: 20, imageUrl: seven_pm },
  // Add more items as needed
];

const TimelineSlider: React.FC = () => {
  const [sliderValue, setSliderValue] = useState<number>(6);
  const isSmallScreen = useMediaQuery("(max-width:800px)");

  const [currentImage, setCurrentImage] = useState<string | null>(
    timelineData[0].imageUrl
  );

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    const selectedHour = newValue as number;
    setSliderValue(selectedHour);

    // Find the corresponding image for the selected hour
    const selectedImage = timelineData.find(
      (item) => item.hour === selectedHour
    )?.imageUrl;
    setCurrentImage(selectedImage || null);
  };

  return (
    <div style={{ margin: "0px auto" }}>
      <div
        className="timeline-items"
        style={{
          margin: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {currentImage && (
          <div className="timeline-item">
            <img
              src={currentImage}
              alt={`item-${sliderValue}`}
              width={isSmallScreen ? "320" : "960"}
              height={isSmallScreen ? "215" : "645"}
            />
          </div>
        )}
      </div>
      <div
        style={{
          backgroundColor: buildxSand,
          width: (isSmallScreen ? "320px" : "960px") as string,
          margin: "0px auto",
          padding: isSmallScreen ? "10px" : "40px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            width: "80%",
            margin: "0px auto",
          }}
        >
          {/* <Header
            text={"Belgrad Dorćol: " + `${sliderValue}:00`}
            backgroundColor={buildxGreen}
            color={buildxLightStone}
            size="sm"
          /> */}
          {/* <div style={{ height: "50px" }}></div> */}
          {/* <p>{"Belgrad Dorćol: " + `${sliderValue}:00`}</p> */}
          <Slider
            // change color
            sx={{
              color: buildxGreen,
              height: 4,
              "& .MuiSlider-thumb": {
                width: 8,
                height: 8,
                transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                "&::before": {
                  boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
                },
                "&:hover, &.Mui-focusVisible": {
                  boxShadow: `0px 0px 0px 8px ${"rgb(0 0 0 / 16%)"}`,
                },
                "&.Mui-active": {
                  width: 20,
                  height: 20,
                },
              },
              "& .MuiSlider-rail": {
                opacity: 0.28,
              },
              "& .MuiSlider-valueLabel": {
                color: buildxLightStone, // Change to the color you want
                backgroundColor: `${buildxGreen}`,
                boxShadow: `2px 2px 3px 8px dark`,
              },
              "& .MuiSlider-markLabel": {
                fontSize: isSmallScreen ? "9px" : "14px", // Adjust the font size as needed
              },
            }}
            value={sliderValue}
            onChange={handleSliderChange}
            min={6}
            max={20}
            step={1}
            marks={marks}
            valueLabelDisplay="on"
            valueLabelFormat={(value) => `${value}:00`}
          />
        </div>
      </div>
    </div>
  );
};

const Solstudie = () => {
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  return (
    <PageDiv backgroundColor={buildxLightStone}>
      <HeroSection
        backgroundColor={buildxLightStone}
        header={"Solstudie"}
        headerColor={buildxGreen}
        direction="flex-end"
        image={noviDorcol}
      />

      <NormalContainer>
        <ParagraphContainer>
          <Header
            text={"Novi Dorćol"}
            backgroundColor={buildxGreen}
            color={buildxLightStone}
            size="lg"
          />
          <p>
            Intill Europas längsta flod Donau ligger kvarteret Dorćol i staden
            Belgrad. Området är känt för sina smala gator, gamla byggnader och
            rika historia. På grund av sin täta stadsplanering är solen en
            särskilt viktig faktor för de nya projekt som byggs i stadsdelen.
            Nedan kan ni se ett urval av de solstudier vi har gjort åt projektet{" "}
            <Lien
              text="Novi Dorćol"
              to="https://www.novidorcol.rs/en/"
              color={buildxGreen}
            ></Lien>{" "}
            som visar hur solen rör sig över bebyggnaderna under dagen.
          </p>
        </ParagraphContainer>
        <ParagraphContainer>
          <Header
            text={"Interaktiv guide"}
            backgroundColor={buildxGreen}
            color={buildxLightStone}
            size="md"
          />
          <Header
            text={"Solstudie - Belgrad Dorćol"}
            backgroundColor={buildxSand}
            color={buildxGreen}
            size="md"
          />
          <p>
            Justera tidslinjen nedan för att interagera med solens position
            under dagen.
          </p>
        </ParagraphContainer>
        <TimelineSlider />
        <div style={{ height: "200px" }}></div>
        <ParagraphContainer>
          <Header
            text={"Video"}
            backgroundColor={buildxGreen}
            color={buildxLightStone}
            size="md"
          />
          <Header
            text={"Solstudie - Belgrad Dorćol"}
            backgroundColor={buildxSand}
            color={buildxGreen}
            size="md"
          />
          <p>Följande video visar solen i rörelse mellan 06:00 och 20:00</p>
          <iframe
            // width="728"
            // height="409"
            width={isSmallScreen ? "320" : "728"}
            height={isSmallScreen ? "170" : "409"}
            src="https://www.youtube.com/embed/P-RCBl1JU4U?si=EL0BMMixx_lO4A6I"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <div style={{ height: "100px" }}></div>
          <div
            style={{
              backgroundColor: buildxSand,
              padding: "10px",
              width: "72%",
            }}
          >
            <p style={{ margin: "0" }}>
              För mer information om projektet 'Novi Dorćol',{" "}
              <Lien
                text={"läs mer här"}
                to="https://www.novidorcol.rs/en/"
                color={buildxGreen}
              />
              .
            </p>
          </div>
        </ParagraphContainer>
      </NormalContainer>
    </PageDiv>
  );
};

export default Solstudie;
