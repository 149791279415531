import React, {
  Suspense,
  createContext,
  lazy,
  useEffect,
  useState,
} from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useSpring, animated } from "@react-spring/web";

import {
  BrowserRouter,
  Link,
  Route,
  Router,
  Routes,
  useLocation,
} from "react-router-dom";
import { Dialog, Divider, useMediaQuery } from "@mui/material";
import { NavBar } from "./components/Navbar";
import { Home } from "./pages/Home";
import { NotFound } from "./pages/NotFound";
import { GeneralConsultant } from "./pages/Services/GeneralConsultant";
import { Footer } from "./components/Footer";
import { TestHero } from "./pages/TestHero";
import ScrollDownAnimation from "./components/ScrollDownAnimation";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import useScrollToTop from "./hooks/useScrollToTop";
import ScrollDetector from "./components/ScrollDetector";
import { flushSync } from "react-dom";
import { PageTransition } from "./components/PageTransition";
import { PdfViewer } from "./pages/PdfViewer";
import Solstudie from "./pages/Solstudie";

const SecondHome = lazy(() => import("./pages/SecondHome"));
const About = lazy(() => import("./pages/About"));
const Projects = lazy(() => import("./pages/Projects"));
const Staffing = lazy(() => import("./pages/Services/Staffing"));
const TurnkeyContract = lazy(() => import("./pages/Services/TurnkeyContract"));
const Design = lazy(() => import("./pages/Services/Design"));
const Mimer = lazy(() => import("./pages/Mimer/Mimer"));
const Contact = lazy(() => import("./pages/Contact"));
const Sustainability = lazy(() => import("./pages/Sustainability"));
const DigitalSolutions = lazy(() => import("./pages/DigitalSolutions"));
const Project = lazy(() => import("./pages/Project"));

export const LanguageContext = createContext<any>({});
export const ScrollContext = createContext<any>(null);
export const LoadedContext = createContext<any>(null);

const AppRoutes = ({
  setNavColorFlag,
  setIsScrollTop,
  setRenderFooter,
}: {
  setNavColorFlag: (arg: number) => void;
  setIsScrollTop: (arg: boolean) => void;
  setRenderFooter: (arg: boolean) => void;
}) => {
  const location = useLocation();
  const [isTrueForOneSecond, setIsTrueForOneSecond] = useState(false);

  useScrollToTop(); // Use the custom hook

  useEffect(() => {
    if (
      location.pathname === "/carbon-cost-analysis" ||
      location.pathname === "/contact"
    ) {
      setRenderFooter(false);
    } else {
      setRenderFooter(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    setIsTrueForOneSecond(true);

    const timeout = setTimeout(() => {
      setIsTrueForOneSecond(false);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [location.pathname]);

  return (
    // <PageTransition>
    <div className="transition-group-container">
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<SecondHome />} />
            <Route
              path="/about"
              element={<About setNavColorFlag={setNavColorFlag} />}
            />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/:id" element={<Project />} />
            <Route path="/general-consult" element={<GeneralConsultant />} />
            <Route path="/staffing" element={<Staffing />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/turnkey-contract" element={<TurnkeyContract />} />
            <Route path="/sustainability" element={<Sustainability />} />
            <Route path="/design" element={<Design />} />
            <Route path="/digital-solutions" element={<DigitalSolutions />} />
            <Route path="/carbon-cost" element={<PdfViewer />} />
            <Route path="/solstudie-novi-dorcol" element={<Solstudie />} />
            <Route
              path="/carbon-cost-analysis"
              element={
                <Mimer
                  setNavColorFlag={setNavColorFlag}
                  setIsScrollTop={setIsScrollTop}
                />
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </div>
    </div>
    // </PageTransition>
  );
};

function App() {
  const [language, setLanguage] = useState<boolean>(false);
  const [isScrollTop, setIsScrollTop] = useState<boolean>(true);
  const [nightMode, setNavColorFlag] = useState<number>(0);
  const [renderFooter, setRenderFooter] = useState<boolean>(true);

  return (
    <LanguageContext.Provider value={{ languageFlag: language, setLanguage }}>
      <ScrollContext.Provider value={setIsScrollTop}>
        <BrowserRouter>
          <ScrollDetector />
          <NavBar
            navColorFlag={nightMode}
            setNavColorFlag={setNavColorFlag}
            isScrollTop={isScrollTop}
          />

          <AppRoutes
            setRenderFooter={setRenderFooter}
            setNavColorFlag={setNavColorFlag}
            setIsScrollTop={setIsScrollTop}
          />
          {renderFooter && <Footer />}
        </BrowserRouter>
      </ScrollContext.Provider>
    </LanguageContext.Provider>
  );
}

export default App;
