import React from "react";

export const PageDiv = ({
  children,
  backgroundColor,
}: {
  children: React.ReactNode;
  backgroundColor?: string;
}) => {
  if (backgroundColor) {
    return (
      <div
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        {children}
      </div>
    );
  } else {
    return <div>{children}</div>;
  }
};
