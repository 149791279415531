import React, { useEffect, useContext } from "react";
import { ScrollContext } from "../App";

function ScrollDetector() {
  const scroll = useContext(ScrollContext);

  const handleScroll = () => {
    const currentScrollPosition = window.pageYOffset;
    scroll(currentScrollPosition === 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scroll.setIsScrollTop]);

  return null;
}

export default ScrollDetector;
