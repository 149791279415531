import { useMediaQuery } from "@mui/material";
import React from "react";
import { buildxDarkBlue, buildxLightStone } from "../data/colors";

interface HeaderProps {
  text: string;
  size?: "xl" | "lg" | "md" | "sm" | "xs";
  color?: string;
  backgroundColor?: string;
}

export const Header = (props: HeaderProps) => {
  const { text, size, color, backgroundColor } = props;
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  const isBigScreen = useMediaQuery("(min-width:2000px)");

  let fontSize: string = "48px";
  if (size === "xl") {
    fontSize = "60px";
  } else if (size === "lg") {
    fontSize = "48px";
  } else if (size === "md") {
    fontSize = "36px";
  } else if (size === "sm") {
    fontSize = "28px";
  } else if (size === "xs") {
    fontSize = "20px";
  }

  if (isSmallScreen) {
    fontSize = `${Math.max(
      0,
      parseInt(fontSize, 10) - parseInt(fontSize, 10) * 0.4
    )}px`;
  } else if (isBigScreen) {
    fontSize = `${Math.max(
      0,
      parseInt(fontSize, 10) + parseInt(fontSize, 10) * 0.7
    )}px`;
  }

  return (
    <div>
      {size ? (
        <h1
          className="header"
          style={{
            fontSize: fontSize,
            color: color ?? buildxLightStone,
            backgroundColor: backgroundColor,
          }}
        >
          {text}
        </h1>
      ) : (
        <h1
          className="header"
          style={{
            color: color,
            backgroundColor: backgroundColor,
          }}
        >
          {text}
        </h1>
      )}
    </div>
  );
};
