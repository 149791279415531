import { useState, useEffect } from "react";

function ScrollDownAnimation() {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      // Hide the scroll down animation when the user has scrolled down
      if (window.pageYOffset > window.innerHeight * 0.3) {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = () => {
    // Scroll to the bottom of the page when the user clicks the animation
    // window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    setIsVisible(false);
  };

  return (
    <div
      className={`scroll-down-animation ${isVisible ? "visible" : ""}`}
      onClick={handleClick}
    >
      <svg viewBox="12 12 12 12">
        <path d="M12 20V4M18 12l-6 6-6-6" />
      </svg>
    </div>
  );
}

export default ScrollDownAnimation;
