import React from "react";
import { Link } from "react-router-dom";
import { buildxGreen } from "../data/colors";

interface Lien {
  to: string;
  text: string;
  color?: string;
  target?: string;
}

export const Lien = (props: Lien) => {
  const { to, text, color, target } = props;

  return (
    <Link
      to={to}
      className={
        color === buildxGreen ? "hero-highlight-green" : "hero-highlight"
      }
      target={target ? "_blank" : ""}
    >
      {text}
    </Link>
  );
};
